<template>
  <section id="">
    <b-tabs
      vertical
      nav-wrapper-class="nav-vertical"
    >
      <b-tab v-for="lesson in data" :key="lesson.id" :title="lesson.translation ? lesson.name+'-'+lesson.translation.name : lesson.name">
        <b-card>
          <b-row>
            <b-col cols="5">
              <h4>
                {{ lesson.name }} {{ lesson.translation ? (lesson.translation.name) : '' }}
              </h4>
            </b-col>
            <b-col cols="4">
              <h5>Exercises: {{ lesson.exercises_count }}</h5>
            </b-col>
            <b-col cols="3">
              <b-button 
                :to="{ name: 'teacher-exercises-view', params: { chapter_id: lesson.section.chapter.id, section_id: lesson.section.id, lesson_id: lesson.id } }"
                variant="outline-warning"
              >
                  View Exercises
              </b-button>
            </b-col>
          </b-row>
          <b-card-text>
            <strong>Chapter: </strong> {{ lesson.section.chapter.name }} <strong>| </strong>
            <strong>Section: </strong> {{ lesson.section.name }}
          </b-card-text>
          <h4>Guide</h4>
          <b-row v-if="lesson.guide.length">
            <b-col :cols="lesson.guide[0].translation ? '6' : '12'">
              <b-card v-html="lesson.guide[0].guide"></b-card>
            </b-col>
            <b-col cols="6" v-if="lesson.guide[0].translation">
              <b-card v-html="lesson.guide[0].translation.guide"></b-card>
            </b-col>
          </b-row>
          <b-card v-else>
            <b-card-text class="d-flex justify-content-center">No Guide Provided</b-card-text>
          </b-card>
        </b-card>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BButton, BCardText, BTabs, BTab } from "bootstrap-vue";
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BTabs, 
    BTab,
  },
  data() {
    return {
      data: [],
    };
  },
  async mounted() {
    await this.$http
      .get(
        `admin/teacher/section/${router.currentRoute.params.section_id}/lessons`
      )
      .then((response) => {
        this.data = response.data.data;
      });
  },
};
</script>
